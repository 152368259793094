/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
      // JavaScript to be fired on all pages

      //toggle search
      $( ".js--libby-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).addClass( "libby-search-overlay-wrapper--is-open" );
        //$(this).toggleClass('js--libby-search-trigger--open');
      });

      $( ".js-libby-close-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).removeClass( "libby-search-overlay-wrapper--is-open" );
      });

      //Create Owl Carousel for Hero
      jQuery('.libby-slider').owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        dots: false,
        autoPlay: true,
        responsiveClass: true,
        responsive: true,
        singleItem: true,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true,
        navigation: true,
        navigationText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
      });

      //Create Owl Carousel for Small Slider Hero
      jQuery('.libby_hero_sm_slider').owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        dots: false,
        autoPlay: true,
        responsive: false,
        navigation: true,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true
      });
      
      //header search
      (function($) {
        var catalogSearchURL = $('.libby-searchform').attr('action'), input;

        $('.libby-searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_site' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
          }
        });
        
        $(".button_catalog").click(".button_catalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-s').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_site").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('input.libby-s').attr('placeholder', 'Search the website');
        });


        //Alt
        $('#searchformo').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_website' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
          }
        });
        
        $(".button_cat").click(".button_cat", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-sinput').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_website").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('.libby-sinput').attr('placeholder', 'Search the website');
        });

        //Tabs on Home for Circulation
        $('#nav-Circulation-tabs li:first a').tab('show');

        //JS needed to make BS Tabs work
        $('.nav-tabs li a').click( function(e) {
          e.preventDefault();
          $(this).tab('show');
        });

        //Prevent Jumping
        $('.nav-tabs li a').click( function(e) {
          history.pushState( null, null, $(this).attr('href') );
        });


        //Slider on Overview pages
        jQuery('.picture-slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          autoPlay: true,
          responsive: false,
          navigation: false,
          autoPlayTimeout: 1000,
          autoplayHoverPause: true
        });

        //database toggle
        $( ".js--db-info-trigger" ).click(function() {
          $( this ).prev( '.libby-databases-list__item__overlay' ).addClass( "libby-databases-list__item__overlay--is-open" );
        });

        $( ".js--db-close-info-trigger" ).click(function() {
          $( this ).closest( '.libby-databases-list__item__overlay' ).removeClass( "libby-databases-list__item__overlay--is-open" );
        });

      })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
